import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { navigate } from 'gatsby';
import { Collapse } from 'react-collapse';
import { FaAngleUp, FaAngleDown } from 'react-icons/fa';
import Skeleton from 'react-loading-skeleton';

import store from 'redux/store';
import { getPrevPath, setPrevPath } from 'redux/slices/_smartRecipe';

import HeaderBar from 'components/header-bar/header-bar-v2';
import Recipe from 'components/smart-recipe/Recipe';
import Radio from 'components/UI/Radio';

import { useAPI } from 'utils/api';
import { PATH_SMART_RECIPE_INFO, PATH_MY_RECIPE } from 'utils/constants/paths';
import WebFn, { callAppFunc } from 'utils/jsbridge/index.js';

const Container = styled.div`
  height: 100%;
  overflow: hidden;

  * {
    box-sizing: border-box;
  }
`;

const Content = styled.div`
  padding: 60px 15px 0;
  height: 100dvh;
  display: flex;
  flex-direction: column;
`;

const SkeletonList = styled.div`
  .skeleton-list-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .react-loading-skeleton {
    flex: 0 0 calc((100% - 10px) / 2);
  }
`;

const RecipeList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  overflow-y: scroll;
  padding-bottom: 15px;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const RecipeWrapper = styled.div`
  flex: 0 0 calc((100% - 10px) / 2);
`;

const Tabs = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Tab = styled.div`
  flex: 1;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 3px solid transparent;
  font-size: 18px;
  font-weight: 700;

  ${(props) =>
    props.active &&
    css`
      border-bottom-color: #5fd2da;
      color: #5fd2da;
    `}
`;

const TabPanel = styled.div`
  margin-top: 15px;
  flex: 1;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Btn = styled.div`
  padding: 8px 16px;
  border-radius: 30px;
  border: 1px solid #dddddd;
  background-color: #ffffff;
  color: #868686;
  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.primary &&
    css`
      border: 1px solid #5fd2da;
      background-color: #5fd2da;
      color: #fff;
    `}
`;

const CollapseBtn = styled.div`
  border: 1px solid #ccc;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CollapseContent = styled.div`
  padding: 10px 20px;
  background-color: #f0efef;
  display: flex;
  flex-direction: column;
  gap: 20px 0;
`;

const CollapseContentItem = styled.div``;

const CollapseContentItemLabel = styled.div`
  font-size: 20px;
  font-weight: 700;
`;

const CollapseContentItemOptions = styled.div`
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const CollapseContentItemOption = styled.div``;

const CollapseFooter = styled.div`
  display: flex;
  gap: 0 10px;

  ${Btn} {
    flex: 1;
  }
`;

const tabList = [
  { label: '全部', value: '0' },
  { label: '依食材、菜系', value: '1' },
];

const searchValuesDefault = {
  ingredient: undefined,
  cuisine: undefined,
};

const MyRecipe = (props) => {
  const api = useAPI();
  const showLoadingRef = useRef(true);

  const prevPath = useSelector((state) => getPrevPath(state));

  console.log('prevPath', prevPath);

  const [loading, setLoading] = useState({
    allRecipes: true,
    customRecipes: false,
  });
  const [activeTab, setActiveTab] = useState('0');
  const [isCollapseOpened, setIsCollapseOpened] = useState(false);

  const [allMyRecipes, setAllMyRecipes] = useState({});
  const allMyRecipeList = allMyRecipes.rows ?? [];

  const [myRecipes, setMyRecipes] = useState();
  const myRecipeList = myRecipes?.rows ?? [];

  const [recipeOptionList, setRecipeOptionList] = useState();
  const ingredientList = recipeOptionList?.map((opt) => opt.ingredient) ?? [];
  const cuisineList = [
    ...new Set(recipeOptionList?.map((opt) => opt.cuisine).flat()),
  ];

  const [searchValues, setSearchValues] = useState({ ...searchValuesDefault });

  const fetchOptions = () => {
    api
      .fetchRecipeData()
      .then((data) => setRecipeOptionList(data.info))
      .catch((err) => console.log(err));
  };

  const fetchAllMyRecipes = () => {
    setLoading((state) => ({ ...state, allRecipes: true }));
    api
      .fetchAllMyRecipes()
      .then((data) => setAllMyRecipes(data))
      .catch((err) => console.log(err))
      .finally(() => setLoading((state) => ({ ...state, allRecipes: false })));
  };

  const fetchMyRecipes = (params) => {
    setMyRecipes(undefined);
    setLoading((state) => ({ ...state, customRecipes: true }));
    api
      .fetchMyRecipes({ ...params })
      .then((data) => setMyRecipes(data))
      .catch((err) => alert(err))
      .finally(() =>
        setLoading((state) => ({ ...state, customRecipes: false }))
      );
  };

  // 刪除食譜後的動作
  const handleAfterRemove = () => {
    showLoadingRef.current = false;
    fetchAllMyRecipes();
    fetchMyRecipes({
      item: searchValues.ingredient,
      type: searchValues.cuisine,
    });
  };

  // 查看食譜
  const handleView = (recipeId) => {
    store.dispatch(setPrevPath([...prevPath, PATH_MY_RECIPE]));
    navigate(`${PATH_SMART_RECIPE_INFO}/${recipeId}`);
  };

  // 開關 Collapse
  const handleToggleCollapse = () => {
    setIsCollapseOpened((state) => !state);
  };

  // 清除搜尋條件
  const handleResetSearch = () => {
    setSearchValues({ ...searchValuesDefault });
  };

  // 條件搜尋食譜
  const handleSearch = () => {
    fetchMyRecipes({
      item: searchValues.ingredient,
      type: searchValues.cuisine,
    });
    setIsCollapseOpened(false);
  };

  // 上一頁
  const handleBack = () => {
    store.dispatch(setPrevPath(''));
    if (prevPath.length === 0) {
      callAppFunc('backMain', {});
    } else {
      const newPrevPath = prevPath.slice(0, prevPath.length - 1);
      store.dispatch(setPrevPath(newPrevPath));
      navigate(-1);
    }
  };

  // 頁面初始化
  useEffect(() => {
    fetchOptions();
    fetchAllMyRecipes();
  }, []);

  // 切換 Tab 時要重置搜尋頁設定
  useEffect(() => {
    setMyRecipes(undefined);
    setSearchValues({ ...searchValuesDefault });
    setIsCollapseOpened(false);
  }, [activeTab]);

  return (
    <Container>
      <HeaderBar title="我的食譜" onBack={handleBack} />

      <Content>
        <Tabs>
          {tabList.map((tab) => {
            return (
              <Tab
                key={tab.value}
                active={activeTab === tab.value}
                onClick={() => setActiveTab(tab.value)}
              >
                {tab.label}
              </Tab>
            );
          })}
        </Tabs>

        <TabPanel id="tab-panel">
          <>
            {activeTab === '0' &&
              (loading.allRecipes ? (
                <SkeletonList>
                  <Skeleton
                    containerClassName="skeleton-list-wrapper"
                    count={12}
                    inline
                    height={180}
                    borderRadius={16}
                  />
                </SkeletonList>
              ) : (
                <RecipeList>
                  {allMyRecipeList.map((recipe, idx) => {
                    return (
                      <RecipeWrapper key={idx}>
                        <Recipe
                          data={recipe}
                          showRemove
                          showEyeIcon
                          onAfterRemove={handleAfterRemove}
                          onView={() => handleView(recipe.id)}
                        />
                      </RecipeWrapper>
                    );
                  })}
                </RecipeList>
              ))}

            {activeTab === '1' && (
              <>
                <CollapseBtn onClick={handleToggleCollapse}>
                  搜尋設定 {isCollapseOpened ? <FaAngleUp /> : <FaAngleDown />}
                </CollapseBtn>

                <Collapse isOpened={isCollapseOpened}>
                  <CollapseContent>
                    <CollapseContentItem>
                      <CollapseContentItemLabel>食材</CollapseContentItemLabel>

                      <CollapseContentItemOptions>
                        {ingredientList.map((ingredient, idx) => {
                          return (
                            <CollapseContentItemOption key={idx}>
                              <Radio
                                name="ingredient"
                                value={ingredient}
                                checked={searchValues.ingredient === ingredient}
                                onChange={(e) => {
                                  setSearchValues((state) => ({
                                    ...state,
                                    ingredient: e.target.value,
                                  }));
                                }}
                              >
                                {ingredient}
                              </Radio>
                            </CollapseContentItemOption>
                          );
                        })}
                      </CollapseContentItemOptions>
                    </CollapseContentItem>

                    <CollapseContentItem>
                      <CollapseContentItemLabel>菜系</CollapseContentItemLabel>

                      <CollapseContentItemOptions>
                        {cuisineList.map((cuisine, idx) => {
                          return (
                            <CollapseContentItemOption key={idx}>
                              <Radio
                                name="cuisine"
                                value={cuisine}
                                checked={searchValues.cuisine === cuisine}
                                onChange={(e) => {
                                  setSearchValues((state) => ({
                                    ...state,
                                    cuisine: e.target.value,
                                  }));
                                }}
                              >
                                {cuisine}
                              </Radio>
                            </CollapseContentItemOption>
                          );
                        })}
                      </CollapseContentItemOptions>
                    </CollapseContentItem>

                    <CollapseFooter>
                      <Btn onClick={handleResetSearch}>清除設定</Btn>
                      <Btn primary onClick={handleSearch}>
                        確定
                      </Btn>
                    </CollapseFooter>
                  </CollapseContent>
                </Collapse>

                {loading.customRecipes ? (
                  <SkeletonList style={{ marginTop: 10 }}>
                    <Skeleton
                      containerClassName="skeleton-list-wrapper"
                      count={12}
                      inline
                      height={180}
                      borderRadius={16}
                    />
                  </SkeletonList>
                ) : (
                  <RecipeList style={{ marginTop: 10 }}>
                    {myRecipes && myRecipeList.length === 0
                      ? '查無符合食譜。'
                      : myRecipeList.map((recipe) => {
                          return (
                            <RecipeWrapper key={recipe.id}>
                              <Recipe
                                data={recipe}
                                showRemove
                                onAfterRemove={handleAfterRemove}
                                onView={() => handleView(recipe.id)}
                              />
                            </RecipeWrapper>
                          );
                        })}
                  </RecipeList>
                )}
              </>
            )}
          </>
        </TabPanel>
      </Content>
    </Container>
  );
};

export default MyRecipe;

import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0 5px;
  word-break: keep-all;

  input[type='radio'] {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid rgb(51, 51, 51);
    appearance: none;
    background-color: #fff;

    &:checked {
      border: 5px solid #5fd2da;
    }
  }
`;

const Radio = (props) => {
  const { checked, children, name, onChange, value } = props;

  return (
    <Container>
      <input
        id={value}
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={value}>{children}</label>
    </Container>
  );
};

export default Radio;
